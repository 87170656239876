<template>
  <div class="user-add">
    <div class="header-title">
      <i class="el-icon-back bach" @click="previous" />
      <span>{{ $route.meta.title }}</span>
    </div>
    <div>
      <div class="next-form-item-label">
        <label>用户账号信息</label>
      </div>
      <el-form
        ref="ruleForm"
        :inline="true"
        :model="form"
        :rules="rules"
        class="demo-form-inline"
        label-position="top"
      >
        <el-form-item label="登录名称" prop="name">
          <el-input v-model="form.name" style="width: 400px">
            <span slot="suffix">{{ domainName }}</span>
          </el-input>
        </el-form-item>
        <el-form-item label="所属域" prop="name">
          <el-select v-model="form.domain_id" placeholder="请选择">
            <el-option
              v-for="item in domain_list"
              :key="item.id"
              :label="item.name"
              :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="显示名称">
          <el-input v-model="form.display_name" style="width: 300px" />
        </el-form-item>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注">
              <el-input
                v-model="form.remark"
                :autosize="{ minRows: 4, maxRows: 4}"
                style="width: 500px"
                type="textarea"
                :rows="2"
                placeholder=""
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <div class="btn-con" style="float: left">
            <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
            <el-button @click="previous">取消</el-button>
          </div>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script>
import { domain_drop_down_menu, add_group } from '@/api'

export default {
  data () {
    return {
      domain_list: [],
      form: {
        radio: 1
      },
      rules: {
        name: [{ required: true, message: '请输入登录名称', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      }
    }
  },
  computed: {
    domainName () {
      const obj = this.domain_list.find(item => item.id == this.form.domain_id) || {}
      return obj.name ? '@' + obj.name : ''
    }
  },
  created () {
    this.get_domain_list()
  },
  methods: {
    get_domain_list () {
      domain_drop_down_menu().then(res => {
        console.log(res)
        this.domain_list = res.data
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            name: this.form.name,
            display_name: this.form.display_name,
            domain_id: this.form.domain_id,
            remark: this.form.remark
          }
          add_group(params).then(res => {
            this.$message({
              showClose: true,
              message: 'success',
              type: 'success'
            })
            this.$router.push('/groups')
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    previous () {
      this.$router.push('/groups')
      this.$refs['ruleForm'].resetFields()
    }
  }
}
</script>

<style lang="less">
.user-add {
  .el-radio-group .el-radio {
    display: block;
    margin-bottom: 20px;
  }
}
</style>
